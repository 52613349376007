.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* comentario */

.header {
  width: 100%;
  min-height: 50%;
  /* background-image: url(/public/images/png/bg_1.png); */
  background-repeat: no-repeat;
  background-position: right;
  background-size: 70%;
  background-attachment: fixed;
}
.video_background {
  width: 40%; 
  min-height: 50%;
  position: absolute; 
  top: 40%; 
  right: 8%; 
  object-fit: cover;
}

@media (max-width: 768px) {
  .video_background {
    display: none;
  }
}

/* .header::before {
  content: "";
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(39, 40, 49, 0.1)
  );
} */

.header a {
  color: #0b2447;
}

.header a:hover {
  color: #a5d7e8;
  text-shadow: 1px 1px 2px #0b2447;
}

#llamar {
  background-color: #a5d7e8;
  color: white;
  letter-spacing: 5px;
  font-size: 18px;
  text-align: center;
  padding: auto;
  text-transform: inherit;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.navbar-edit {
  z-index: 20;
  width: 100%;
}

.border-color{
  border-bottom: solid 5px rgba(165, 215, 232, 0.5);
  box-shadow: 0px 0px 25px #576cbc;
}

@media (max-width: 768px) {
  .header {
    text-align: left;
    background-position: left;
  }

  .text-5 {
    width: 100%;
  }

  .header {
    width: 100%;
    min-height: 100vh;
    background-image: url(/public/images/png/bg_1.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 50%;
    background-attachment: fixed;
  }
}

.tamano {
  min-height: 100vh;
  z-index: 20;
}

.text-1 {
  color: #0b2447;
  font-weight: 500;
}

.text-move {
  color: #a5d7e8;
  text-shadow: 1px 1px 1px #576cbc;
  font-style: normal;
  font-weight: 600;
}

.text-3 {
  color: #576cbc;
}

.text-4 {
  color: #0b2447;
}

.text-iot {
  color: #a5d7e8;
  text-shadow: 1px 1px 2px #0b2447;
}

.text-5 {
  color: #0b2447;
  text-align: justify;
}

#card1,
#card2,
#card3 {
  /*Efecto de ampliar carta con hover*/
  border: solid 10px #576cbc;
  border-radius: 20px;
  /* background-color: red; */
  overflow: hidden;
  transition: all ease-out 0.2s;
  text-align: justify;
  height: 100%;
}

#card1:hover,
#card2:hover,
#card3:hover {
  transform: scale(1.05);
}

.puntero {
  cursor: default;
}